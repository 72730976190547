import React from 'react';
import './App.css'; 
import NavigationBar from './NavigationBar';
import Introduction from './Introduction';
// import Projects from './Projects';
// import Contact from './Contact';

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <Introduction />
      {/* <Projects /> */}
      {/* <Contact /> */}
    </div>
  );
}

export default App;
