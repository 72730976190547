import React from 'react';

const NavigationBar = () => {
  return (
    <nav id="navbar">
      <div className="logo">
        <img src="images/logo.JPG" alt="Yan Chen" width="60" />
      </div>
      <ul className="nav-links">
        <li><a href="home">Home</a></li>
        {/* <li><a href="#about">About</a></li> */}
        <li><a href="projects">Projects</a></li>
        {/* <li><a href="#contact">Contact</a></li> */}
      </ul>
    </nav>
  );
};

export default NavigationBar;