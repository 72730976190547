import React from 'react';

const Introduction = () => {
  return (
    <section id="introduction" className="intro-container">
      <div className="intro-text">
        <h2>Hey, I'm Yan Chen</h2>
        <p align="justify">
          I'm a <b>computer science student</b> graduating in <b>December 2023</b> with three years of experience in Java and one year in C. I also have exposure to Python, JavaScript, MIPS, and Haskell. I'm currently looking for <b>a junior software engineer job</b>.
        </p>
        <p align="justify">
          <a href="Yan_Chen_Resume.pdf" target="_blank" rel="noopener noreferrer">
            <button style={{ fontSize: "16px", backgroundColor: "#708238", color: "white", border: "none", padding: "10px 20px", borderRadius: "3px", cursor: "pointer" }}>
              View Resume
            </button>
          </a>
        </p>
        <p align="justify">
          <a href="mailto:ychenfolio@gmail.com" target="_blank" rel="noopener noreferrer">
            <img align="center" src="images/gmail.svg" alt="ychenfolio" height="30" width="35" />
          </a>   <a href="https://github.com/ychenfolio" target="_blank" rel="noopener noreferrer">
            <img align="center" src="images/github.svg" alt="ychenfolio" height="35" width="45" />
          </a>    <a href="https://linkedin.com/in/ychenfolio" target="_blank" rel="noopener noreferrer">
            <img align="center" src="images/linked-in.svg" alt="ychenfolio" height="30" width="40" />
          </a>
        </p>
      </div>
      <div className="image-container">
        <img src={"images/coding_cat.jpg"} alt="Coding Cat" className="profile-image" />
      </div>
    </section>
  );
};

export default Introduction;